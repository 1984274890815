import React from "react"

export default () =>

<div className="ui middle aligned stackable grid container">
  <div className="row">
      <div className="eight wide column">
            <h1 style={{fontFamily: 'Open Sans, sans-serif', color: '#404040', fontSize: '4em'}} >Contracts as code</h1>
            <h2  style={{fontFamily: 'Space mono, sans-serif', color: '#606060'}}>Hudub is an open source online platform that leverages sofware development best practices and tools to manage contracts.</h2>
            <h2  style={{fontFamily: 'Space mono, sans-serif', color: '#606060'}}>Open source and free to use.</h2>
    </div>
      <div className="eight wide column">
        <img src="home.svg" alt="home" />
      </div>
  </div>
</div>
